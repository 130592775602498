import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { WithAppContext } from './context/AppContext';
import AppRouter from './AppRouter';
import './styles/dynamiccss.css';
import './app.scss';
import Layout from './components/Layout/Layout';
import { useDispatch } from 'react-redux';
import { getTranslationData } from './redux/action/action';
const App = () => {
  let root = document.documentElement;
  const selectedGameSelected = localStorage.getItem(['selectedGame']);
  if (selectedGameSelected && JSON.parse(selectedGameSelected).mainColour) {
    root.style.setProperty('--primary', JSON.parse(selectedGameSelected).mainColour);
  }
  const dispatch = useDispatch();
  useEffect(() => {
    getTrans();
  }, []);
  const getTrans = () => {
    dispatch(getTranslationData());
  };

  return (
    <div>
      <BrowserRouter>
        <WithAppContext>
          <Layout>
            <AppRouter />
          </Layout>
        </WithAppContext>
      </BrowserRouter>
    </div>
  );
};

export default App;
